
import { defineComponent } from "vue";
import useApplications from "@/use/application";
import Page from "../components/shared/layout/Page.vue";
import Loader from "@/components/shared/Loader.vue";
import { useRoute } from "vue-router";

import { mapActions, mapGetters, mapState } from "vuex";

export default defineComponent({
  setup() {
    const route = useRoute();
    const { getUserApplications, getUserApplication } = useApplications();
    return { getUserApplications, getUserApplication, route };
  },
  components: {
    Page,
    Loader,
  },
  data: () => ({
    userApplications: [],
    loading: true,
  }),
  computed: {
    ...mapState(["applications", "configuration"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
  },
  methods: {
    ...mapActions(["setApplications"]),
    viewApplication(referenceId: number) {
      const query = this.$route.query as any;
      // redirect to application page
      this.$router.push({ path: `/applications/${referenceId}`, query });
    },
  },
  watch: {
    $route() {
      console.log("route changed...");
    },
  },
  async mounted() {
    this.userApplications = await this.getUserApplications(this.oidcUser.sub);
    this.setApplications(this.userApplications);

    if ((this.applications || []).length === 1) {
      this.$router.push({
        path: `/applications/${this.applications[0].referenceId}`,
      });
    }

    this.loading = false;
  },
});
